import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import Layout from "../layout/layout"
import LinearBlock from "../layout/linearblock/linearblock"
import { HomePage } from "../components/enum/pages"
import Button from "../components/button/button"
import {
  layoutContainer,
  containerNewsList,
  imageTop,
  homeImageContainer,
  overlay,
  overlayLogo,
  textOnImage,
  subtitle,
  button,
} from "../styles/home.module.scss"
import { imageHead } from "../styles/image.module.scss"
import { iconGreen } from "../components/icon/icon.module.scss"
import logoTransparent from "../assets/images/logo-blanc.svg"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../seo"
import TitledParagraph from "../components/titledParagraph/titledParagraph"
import { LatestNews } from "./newsList"

export const pageQuery = graphql`
  {
    allStrapiAccueil(limit: 10) {
      nodes {
        Banner
        CuttingPart
        CuttingPartTitle
        PaperPart
        PaperPartTitle
        Story
        StoryTitle
        TransformPart
        TransformPartTitle
        Values
        ValuesTitle
        published_at
        updated_at
        BannerImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        StoryImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        TransformPartImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        ValuesImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        PaperPartImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        CuttingPartImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allStrapiArticle(
      limit: 4
      sort: { fields: publishedAt, order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          strapiId
          slug
          title
          description
          publishedAt
          category {
            name
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

function Home(props) {
  const { intl, data } = props
  const metaTitle = intl.formatMessage({ id: "menu.home" })
  const metaDescription = intl.formatMessage({ id: "home.description" })
  const homeAboutCTA = intl.formatMessage({ id: "home.aboutCTA" })
  const aboutCTAClickHandler = () => {
    navigate("/services/")
  }

  const accueilData = data.allStrapiAccueil.nodes[0]
  const services = [
    <GatsbyImage
      alt=""
      image={
        accueilData.TransformPartImg.localFile.childImageSharp.gatsbyImageData
      }
      className={imageHead}
    />,
    <>
      <TitledParagraph
        color="green"
        title={accueilData.TransformPartTitle}
        content={accueilData.TransformPart}
      />
      <Button className={button} onClick={aboutCTAClickHandler}>
        {homeAboutCTA}
      </Button>
    </>,

    <GatsbyImage
      alt=""
      image={
        accueilData.CuttingPartImg.localFile.childImageSharp.gatsbyImageData
      }
      className={imageHead}
    />,
    <>
      <TitledParagraph
        color="green"
        title={accueilData.CuttingPartTitle}
        content={accueilData.CuttingPart}
      />
      <Button className={button} onClick={aboutCTAClickHandler}>
        {homeAboutCTA}
      </Button>
    </>,

    <GatsbyImage
      alt=""
      image={accueilData.PaperPartImg.localFile.childImageSharp.gatsbyImageData}
      className={imageHead}
    />,
    <>
      <TitledParagraph
        color="green"
        title={accueilData.PaperPartTitle}
        content={accueilData.PaperPart}
      />
      <Button className={button} onClick={aboutCTAClickHandler}>
        {homeAboutCTA}
      </Button>
    </>,
  ]

  return (
    <Layout active={HomePage}>
      <SEO
        seo={{
          metaTitle: metaTitle,
          metaDescription: metaDescription,
          locale: intl.locale,
        }}
      />

      <div className={layoutContainer}>
        <div className={homeImageContainer}>
          <GatsbyImage
            alt=""
            image={
              accueilData.BannerImg.localFile.childImageSharp.gatsbyImageData
            }
            className={imageTop}
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <div className={overlay} />
          <img className={overlayLogo} src={logoTransparent} alt="Logo" />
          <div className={textOnImage}>
            <div className={subtitle}>{accueilData.Banner}</div>
          </div>
        </div>

        <LinearBlock color="orange" oneComponent={<h1> Services </h1>} />

        <LinearBlock color="white" listComponent={services} />

        <LinearBlock
          color="orange"
          oneComponent={<h1> Qui sommes nous? </h1>}
        />

        <LinearBlock
          color="white"
          leftComponent={
            <GatsbyImage
              image={
                accueilData.StoryImg.localFile.childImageSharp.gatsbyImageData
              }
              className={iconGreen}
              alt=""
            />
          }
          rightComponent={
            <TitledParagraph
              color="green"
              title={accueilData.StoryTitle}
              content={accueilData.Story}
            />
          }
        />
        <LinearBlock
          color="white"
          leftComponent={
            <GatsbyImage
              image={
                accueilData.ValuesImg.localFile.childImageSharp.gatsbyImageData
              }
              className={iconGreen}
              alt=""
            />
          }
          rightComponent={
            <TitledParagraph
              color="green"
              title={accueilData.ValuesTitle}
              content={accueilData.Values}
            />
          }
        />

        <LinearBlock color="lightgray" oneComponent={<h1> Actualités </h1>} />

        <LinearBlock
          oneComponent={
            <div className={containerNewsList}>
              <LatestNews
                articleEdges={data.allStrapiArticle.edges}
                intl={intl}
              />
            </div>
          }
        />
      </div>
    </Layout>
  )
}

export default injectIntl(Home)
